import "@hulanbv/hulan-services-typings";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { colors } from "@hulanbv/dashboard-components/distribution/domain/constants/colors.constants";
import "./styles/global.module.css";

colors.primary = "#6567b0";

const render = async () => {
  const { Main } = await import("./components/main");
  return ReactDOM.render(
    <StrictMode>
      <Main />
    </StrictMode>,
    document.getElementById("root"),
  );
};
render();
